
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  public clientVersion: string = process.env.VUE_APP_VERSION;
  public apiVersion = '';

  async created(): Promise<void> {
    try {
      const res = await this.$http.get(
        `${process.env.VUE_APP_API_URL}/api_version`,
      );

      this.apiVersion = res?.data;
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }
  }
}
