// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { Commit } from 'vuex';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGOUT = 'LOGOUT';

interface AuthState {
  token: string;
}

const initialState = {
  token: JSON.parse(String(localStorage.getItem('token'))) || '',
} as AuthState;

const authModule = {
  namespaced: true,
  state: initialState,
  actions: {
    signin(
      { commit }: { commit: Commit }, { token }: { token: string },
    ): void {
      commit(AUTH_SUCCESS, { token });
    },
    logout({ commit }: { commit: Commit }): void {
      commit(LOGOUT);
    },
  },
  mutations: {
    [AUTH_SUCCESS](state: AuthState, { token }: { token: string }): void {
      state.token = token;
      localStorage.setItem('token', JSON.stringify(token));
    },
    [LOGOUT](state: AuthState): void {
      state.token = '';
      localStorage.removeItem('token');
    },
  },
  getters: {
    userId: (state: AuthState): any => {
      if (!state.token) return null;

      const decodedTokenData = jwt_decode(state.token) as any;
      return decodedTokenData.sub;
    },
    token: (state: AuthState): string => state.token,
    isLoggedIn: (state: AuthState): boolean => Boolean(state.token),
    hasSessionExpired: (state: AuthState): boolean => {
      if (!Date.now) {
        // Shim for IE 8
        Date.now = () => new Date().getTime();
      }
      if (state.token) {
        const decoded = jwt_decode(state.token) as any;
        const { exp } = decoded;
        const currentTimestamp = Math.floor(Date.now() / 1000);

        return exp < currentTimestamp;
      }
      return false;
    },
    areBusinessDetailsCompleted: (state: AuthState): boolean => {
      if (!state.token) return false;

      const decodedTokenData = jwt_decode(state.token) as any;
      return decodedTokenData.businessDetailsCompleted;
    },
    permissions: (state: AuthState): string[] => {
      if (!state.token) return [];
      const decodedTokenData = jwt_decode(state.token) as any;
      return decodedTokenData.permissions;
    },
    isAuth: (state: AuthState) => (requiredRoles: string[]): boolean => {
      const { token } = state;
      const decodedTokenData = jwt_decode(token) as any;

      const { role } = decodedTokenData;
      const currentUserRoles = role ? [role] : [];

      if (!requiredRoles || requiredRoles.length === 0) return true;
      if (!currentUserRoles || currentUserRoles.length === 0) return false;
      return requiredRoles.some((r) => currentUserRoles.indexOf(r) > -1);
    },
  },
};

export default authModule;
