var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form_container p-4"},[_vm._m(0),_c('h1',{staticClass:"is-size-5 has-text-centered mt-0 mb-5",staticStyle:{"color":"#fe5716"}},[_vm._v(" Bienvenue sur votre plateforme CKDO ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.recaptcha.apply(null, arguments)}}},[_c('b-field',{attrs:{"id":"email-test-puppeteer","label":"Email","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.email.$error },"message":[
        { 'Email invalide': _vm.$v.form.email.$error },
        { 'Champs requis': !_vm.$v.form.email.required && _vm.$v.form.email.$dirty },
      ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"johndoe@proxity.com","type":"email"},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.email.$model"}})],1),_c('b-field',{attrs:{"id":"password-test-puppeteer","label":"Mot de passe","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.password.$error },"message":[
        { 'Champs requis': !_vm.$v.form.password.required && _vm.$v.form.password.$dirty },
      ]}},[_c('b-input',{attrs:{"rounded":"","type":"password","password-reveal":""},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", $$v)},expression:"$v.form.password.$model"}})],1),_c('b-field',{staticClass:"mt-5 has-text-left"},[_c('b-checkbox',{attrs:{"type":"is-primary"},model:{value:(_vm.form.rememberMe),callback:function ($$v) {_vm.$set(_vm.form, "rememberMe", $$v)},expression:"form.rememberMe"}},[_vm._v(" Se rappeler de moi ")])],1),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCguField),expression:"showCguField"}],staticClass:"mt-5 has-text-left is-size-7"},[_c('b-checkbox',{model:{value:(_vm.form.termsOfService),callback:function ($$v) {_vm.$set(_vm.form, "termsOfService", $$v)},expression:"form.termsOfService"}},[_vm._v(" j'accepte les "),_c('a',{staticClass:"has-text-weight-medium",attrs:{"href":"#"}},[_vm._v("conditions générales d'utilisation *")])])],1),_c('b-notification',{staticClass:"mt-4",attrs:{"type":"is-light","aria-close-label":"Fermer message d'erreur","role":"alert","closable":""},model:{value:(_vm.isInfoMsgActive),callback:function ($$v) {_vm.isInfoMsgActive=$$v},expression:"isInfoMsgActive"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('b-button',{staticClass:"mt-6",attrs:{"id":"login-test-puppeteer","label":"Se connecter","native-type":"submit","type":"is-secondary","expanded":"","disabled":_vm.$v.$invalid,"rounded":"","icon-left":"location-enter"}}),_c('div',{staticClass:"has-text-weight-light mt-4 has-text-centered"},[_c('router-link',{staticClass:"has-text-grey-dark",attrs:{"to":"/account/password_reset/verify"}},[_vm._v(" Mot de passe oublié ? ")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"is-flex is-justify-content-center"},[_c('img',{staticClass:"image_card--ckdo",attrs:{"src":require("@/assets/img/image-ckdo-card.png"),"alt":"Proxity carte cadeau image"}})])
}]

export { render, staticRenderFns }