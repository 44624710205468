import { AxiosPromise } from 'axios';
import axiosInstance from '@/helpers/axios.config';
import { SignInDto, SignUpDto, VerifyAccountDto } from './dto';
import { UpdateUserDto } from '../users/dto';

class AuthService {
  protected get baseUrl(): string {
    return `${process.env.VUE_APP_API_URL}/auth`;
  }

  signIn(signInDto: SignInDto): Promise<AxiosPromise> {
    return axiosInstance.post(`${this.baseUrl}/ckdo/signin`, signInDto);
  }

  signUp(signUpDto: SignUpDto): Promise<AxiosPromise> {
    return axiosInstance.post(`${this.baseUrl}/ckdo/signup`, signUpDto);
    // return axiosInstance.post(`${this.baseUrl}/signin`, signUpDto);
  }

  verifyAccount(verifyAccountDto: VerifyAccountDto): Promise<AxiosPromise> {
    return axiosInstance.post(
      `${this.baseUrl}/accounts/verify`,
      verifyAccountDto,
    );
  }

  verifyBusinessDetails(id: string, updateUserDto: UpdateUserDto): Promise<AxiosPromise> {
    return axiosInstance.patch(
      `${this.baseUrl}/accounts/ckdo/${id}/verify_business_details`, {
        ...updateUserDto,
        category: updateUserDto.category,
      },
      // `${this.baseUrl}/accounts/${id}/verify_business_details`, updateUserDto,
    );
  }

  sendResetPasswordEmail(email: string): Promise<AxiosPromise> {
    return axiosInstance.post(
      `${this.baseUrl}/accounts/password_reset/send_reset_token`, {
        email,
      },
    );
  }

  resetPassword(token: string, password: string): Promise<AxiosPromise> {
    return axiosInstance.post(
      `${this.baseUrl}/accounts/password_reset`, {
        token,
        password,
      },
    );
  }
  verifyCaptcha(token: string): Promise<AxiosPromise> {
    return axiosInstance.post(
      `${this.baseUrl}/captchas/verify`, {
        token,
      },
    );
  }
}

export default new AuthService();
