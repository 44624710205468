import './registerComponentHooks';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Buefy from 'buefy';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueConfetti from 'vue-confetti';
import VueGtag from 'vue-gtag';
import VueTheMask from 'vue-the-mask';
import axiosInstance from '@/helpers/axios.config';
import './registerServiceWorker';
import App from './App.vue';
import router from './router';
import store from './store';
import 'moment/locale/fr';
import 'reset-css';
import '@/assets/css/index.scss';

Vue.prototype.$http = axiosInstance;
Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(Buefy);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});
Vue.use(VueConfetti as any);
Vue.use(VueGtag, { config: { id: 'UA-156939122-1' } }, router);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
