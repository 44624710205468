
import { Component, Vue } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import authService from '@/services/auth/authService';

@Component
export default class SignInForm extends Vue {
  public isInfoMsgActive = false;
  public error = '';
  public showCguField: boolean | null = null;
  public form: any = {
    email: '',
    password: '',
    rememberMe: false,
    termsOfService: null,
  };

  validations(): any {
    return {
      form: {
        email: { required, email },
        password: { required },
      },
    };
  }

  get checkIfCguValid():boolean {
    if (this.showCguField === true && this.form.termsOfService === true) {
      return true;
    }
    return false;
  }

  async recaptcha(): Promise<any> {
    if (!this.$v.$invalid) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('SignIn');
        const { data } = await authService.verifyCaptcha(token);
        if (data.success) {
          await this.signIn();
        }
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue.',
        });
      } finally { loadingComponent.close(); }
    }
  }
  async signIn(): Promise<void> {
    if (!this.$v.$invalid) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      this.isInfoMsgActive = false;
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/ckdo/signin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        });

        const data = await response.json();
        const { statusCode, message } = data;

        if (statusCode === 401) {
          this.error = message;
          this.isInfoMsgActive = true;
          return;
        }

        this.showCguField = statusCode === 403;
        this.error = Array.isArray(message) ? message[0] : message;
        this.isInfoMsgActive = true;

        this.$store.dispatch('authModule/signin', {
          token: data.access_token,
        });

        const redirectUrl = this.$route.query.redirectUrl || '/home';

        if (redirectUrl && typeof (redirectUrl) === 'string') {
          this.$router.push({ path: redirectUrl });
        }
      } catch (error: any) {
        const { data } = error.response;
        this.isInfoMsgActive = true;
      } finally {
        loadingComponent.close();
      }
    }
  }
}
