/* eslint-disable */
import axios from 'axios';
import store from '../store';

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getters['authModule/token'];

    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  }, (error) => {
    if (error.response.status === 401) {
      store.dispatch('authModule/logout');
      window.location.replace('/login');
      return error;
    } else {
      return Promise.reject(error);
    }
  },
)

export default axiosInstance;
