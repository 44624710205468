
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Layout from '@/components/Layout.vue';

@Component({
  components: {
    Layout,
  },
})
export default class App extends Vue {
  public layout = Layout;
  public refreshNotification: any = null;

  get transitionName(): string {
    return this.$store.getters['transitionModule/transitionName'];
  }

  get updateExists(): boolean {
    return this.$store.getters['swModule/updateExists'];
  }

  get isRefreshNotificationOpen(): boolean {
    return this.$store.getters['swModule/isRefreshNotification'];
  }

  created(): void {
    // initialize service worker update events
    this.$store.dispatch('swModule/initializeSw');
  }

  mounted(): void {
    // Trick to avoid input zoom in ios
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
    const checkIsIOS = () =>
      // eslint-disable-next-line
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (checkIsIOS()) {
      this.addMaximumScaleToMetaViewport();
    }
  }

  addMaximumScaleToMetaViewport(): void {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = String(el.getAttribute('content'));
      // eslint-disable-next-line no-useless-escape
      const re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  }

  // refresh auto update app, don't need to open modal and click on button
  openRefreshNotification(): void {
    // refresh app
    this.refreshApp();
    // this.refreshNotification = this.$buefy.snackbar.open({
    //   indefinite: true,
    //   message: `
    //       <div>
    //         <div>
    //           <p>Nouvelle version disponible !</p>
    //         </div>
    //       </div>
    //     `,
    //   position: 'is-bottom-left',
    //   actionText: 'Mettre à jour',
    //   queue: false,
    //   onAction: () => {
    //     this.refreshApp();
    //   },
    // });
  }

  refreshApp(): void {
    this.$store.dispatch('swModule/setRefreshNotification', { value: false });
    this.$store.dispatch('swModule/refreshApp');
  }

  @Watch('updateExists', { immediate: true })
  onUpdateExistsChange(val: boolean): void {
    if (val) {
      this.$store.dispatch('swModule/setRefreshNotification', { value: true });
      if (!this.refreshNotification) this.openRefreshNotification();
    }
  }

  @Watch('isRefreshNotificationOpen', {})
  OnIsRefreshNotificationOpenChange(val: boolean): void {
    if (!val && this.refreshNotification) {
      this.refreshNotification.close();
      this.refreshNotification = null;
    }
  }
}
