import { Commit } from 'vuex';

export const SET_CARD = 'SET_CARD';
export const COUNT_CARD = 'COUNT_CARD';
export const RESET_CARD = 'RESET_CARD';

interface CardCredited {
  cardCreditedInfo: any;
  // countCardCredited: number;
}

const initialState: CardCredited = {
  cardCreditedInfo: null,
  // countCardCredited: 1,
};

const giftCardModule = {
  namespaced: true,
  state: initialState,
  actions: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setCard({ commit }: { commit: Commit }, card: any): void {
      commit(SET_CARD, { card });
    },
    setCount({ commit }: { commit: Commit }, count: number): void {
      commit(COUNT_CARD, { count });
    },
    resetCard({ commit }: { commit: Commit }): void {
      commit(RESET_CARD, { card: null });
    },
  },
  mutations: {
    [SET_CARD](state: CardCredited, { card }: { card: any }): void {
      state.cardCreditedInfo = card;
    },
    // [COUNT_CARD](state: CardCredited, { count }: { count: number }): void {
    //   state.countCardCredited = count;
    // },
    // [RESET_CARD](state: CardCredited): void {
    //   state.cardCreditedInfo = null;
    //   state.countCardCredited = 0;
    // },
  },
  getters: {
    card: (state: CardCredited): any => state.cardCreditedInfo,
    // count: (state: CardCredited): number => state.countCardCredited,
  },
};

export default giftCardModule;
