import Vue from 'vue';
import Vuex from 'vuex';

import authModule from './auth/auth.module';
import cardModule from './card/card.module';
import transitionModule from './transition/transition.module';
import giftCardModule from './giftCard/giftCard.module';
import swModule from './sw/sw.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    transitionModule,
    authModule,
    cardModule,
    giftCardModule,
    swModule,
  },
});
