
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Navbar extends Vue {
  public windowInnerWidth = window.innerWidth;

  mounted(): void {
    window.addEventListener('resize', this.onResize);
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }

  get isLogedIn(): boolean {
    return this.$store.getters['authModule/isLoggedIn'];
  }

  get isMobile(): boolean {
    return this.windowInnerWidth < 768;
  }

  logout(): void {
    this.$store.dispatch('authModule/logout');
    this.$router.push({ name: 'Login' });
  }

  showBtn(routeName: string): boolean {
    return this.$route.matched.some(({ name }) => (
      name === routeName
    ));
  }

  onResize(): void {
    this.windowInnerWidth = window.innerWidth;
  }
}
