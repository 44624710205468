
import { Component, Vue } from 'vue-property-decorator';
import SignInForm from '@/components/SignInForm.vue';

@Component({
  components: {
    SignInForm,
  },
})
export default class SignIn extends Vue {}
