import { Commit } from 'vuex';

export const UPDATE_TRANSITION_NAME = 'UPDATE_TRANSITION_NAME';

interface TransitionState {
  transitionName: string,
}

const initialState = {
  transitionName: 'slide-right',
} as TransitionState;

const transitionModule = {
  namespaced: true,
  state: initialState,
  actions: {
    updateTransitionName(
      { commit }: { commit: Commit },
      { transitionName }: { transitionName: string },
    ): void {
      commit(UPDATE_TRANSITION_NAME, { transitionName });
    },
  },
  mutations: {
    [UPDATE_TRANSITION_NAME](
      state: TransitionState,
      { transitionName }: { transitionName: string },
    ): void {
      state.transitionName = transitionName;
    },
  },
  getters: {
    transitionName: (state: TransitionState): string => state.transitionName,
  },
};

export default transitionModule;
