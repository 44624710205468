import { Commit } from 'vuex';

export const SCAN_SUCCESS = 'SCAN_SUCCESS';
export const SCAN_FAILURE = 'SCAN_FAILURE';
export const CODE_SUCCESS = 'CODE_SUCCESS';
export const CODE_FAILURE = 'CODE_FAILURE';

interface CardState {
  card: any;
  validity: boolean;
  error: string;
}

const initialState = {
  card: null,
  validity: false,
  error: '',
} as CardState;

const cardModule = {
  namespaced: true,
  state: initialState,
  actions: {
    scanSuccess(
      { commit }: { commit: Commit }, { card }: { card: any },
    ): void {
      commit(SCAN_SUCCESS, { card });
    },
    scanFailure(
      { commit }: { commit: Commit }, { error }: { error: string },
    ): void {
      commit(SCAN_SUCCESS, { error });
    },
    codeSuccess(
      { commit }: { commit: Commit }, { card }: { card: any },
    ): void {
      commit(CODE_SUCCESS, { card });
    },
    codeFailure(
      { commit }: { commit: Commit }, { error }: { error: string },
    ): void {
      commit(CODE_FAILURE, { error });
    },
  },
  mutations: {
    [SCAN_SUCCESS](state: CardState, { card }: { card: any }): void {
      state.card = card;
      state.validity = true;
      state.error = '';
    },
    [SCAN_FAILURE](state: CardState, { error }: { error: string }): void {
      state.card = null;
      state.error = error;
      state.validity = false;
    },
    [CODE_SUCCESS](state: CardState, { card }: { card: any }): void {
      state.card = card;
      state.validity = true;
      state.error = '';
    },
    [CODE_FAILURE](state: CardState, { error }: { error: string }): void {
      state.card = null;
      state.error = error;
      state.validity = false;
    },
  },
  getters: {
    card: (state: CardState): any => state.card,
    cardValidity: (state: CardState): any => state.validity,
    error: (state: CardState): any => state.error,
  },
};

export default cardModule;
