import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import SignIn from '@/views/SignIn.vue';
import store from '../store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/card-burned-result',
    name: 'CardBurnResult',
    component: () => import('../views/CardBurnResult.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/card-credited',
    name: 'CardCredited',
    component: () => import('../components/CardCredited.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/card-credited-result',
    name: 'CardCreditedResult',
    component: () => import('../views/CardCreditedResult.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/code-result',
    name: 'CodeResult',
    component: () => import('../views/CodeResult.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/contract',
    name: 'Contract',
    component: () => import('../views/Contract.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
    caseSensitive: true,
  },
  {
    path: '/contract-lot-card',
    name: 'ContractLotCard',
    component: () => import('../components/ContractLotCard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/card-code',
    name: 'CardCode',
    component: () => import('../views/CardCode.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../components/TransactionsList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
      roles: ['business_owner'],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: SignIn,
    meta: {
      requiresAuth: false,
      roles: [],
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['authModule/isLoggedIn']) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/account/confirmation',
    name: 'AccountConfirmation',
    component: () => import('../views/AccountConfirmation.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/account/password_reset/verify',
    name: 'PasswordVerify',
    component: () => import('../views/PasswordVerify.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/account/password_reset',
    name: 'PasswordReset',
    component: () => import('../views/PasswordReset.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/politique-de-confidentialite',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/mentions-legales',
    name: 'TermsOfService',
    component: () => import('../views/TermsOfService.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/not-found',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '*',
    redirect: { name: 'PageNotFound' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach((to, from, next) => {
  const toDepth = to.path.split('/').length;
  const fromDepth = from.path.split('/').length;
  const transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';

  store.dispatch('transitionModule/updateTransitionName', { transitionName });
  next();
});

router.beforeEach((to, from, next) => {
  // if route required authentification
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if user is not already authenticated redirect to login page
    const isLoggedIn = store.getters['authModule/isLoggedIn'];
    const hasSessionExpired = store.getters['authModule/hasSessionExpired'];

    if (!isLoggedIn || hasSessionExpired) {
      console.log('Not Logged in or session expired');
      store.dispatch('authModule/logout');
      next({
        path: '/login',
        query: { redirectUrl: to.fullPath },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const requiredRoles = to.meta.roles || [];
      const isAuth = store.getters['authModule/isAuth'](requiredRoles);

      // if user doesn't have required roles redirect to home page
      if (!isAuth) {
        next({ name: from.name || 'Login' });
      } else {
        next();
      }
    }
  } else {
    // page can be accessed by anyone
    next();
  }
});

export default router;
